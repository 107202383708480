<template>
	<el-row style="margin-top: 4px;">
		<el-col :sm="12" style="margin: 0 auto;">
			<el-form :model="codesData" style="margin-top: 100px;">
				<el-tabs v-model="codesData.activeName" type="card" @tab-click="handleClick">
					<!-- URL编码/解码 -->
					<el-tab-pane label="URL编码/解码" name="/url">
						<el-form-item label="">
							<el-col :sm = "24">
								<el-input  type="textarea" :rows="codesData.rows" resize='none'  v-model="codesData.urlSource"></el-input>
							</el-col>
						</el-form-item>
						<div style="margin: 0 auto; text-align: left;">
							<el-button @click="urlHandleEvent(1)" type="primary">编码</el-button>
							<el-button @click="urlHandleEvent(2)" type="success">解码</el-button>
							<el-button @click="textReplacement" type="warning">交换</el-button>
							<el-button @click="removeUrl" type="danger" plain>清空</el-button>
							<el-button  @click="copy(codesData.urlResult)" type="primary" plain>复制</el-button>
						</div>
						
						<el-row style="margin-top: 15px;" v-if="codesData.urlSourceError">
							<el-col :sm = "24">
								<el-alert title="不要淘气( ^___^ ) http协议中url支持最大字符2083"  type="error"  center :closable="false" ></el-alert>
							</el-col>
						</el-row>
						<el-row>
							<el-col :sm = "24">
								<el-form-item style="margin-top: 15px;">
									<el-input readonly  type="textarea" :rows="codesData.rows" resize='none'  v-model="codesData.urlResult"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-tab-pane>
					
					<!-- Unicode/ASSCII编码转换 -->
					<el-tab-pane label="Unicode/ASSCII编码转换" name="/unicode">
						<el-form-item label="">
							<el-col :sm = "24">
								<el-input  type="textarea" :rows="codesData.rows" resize='none'  v-model="codesData.convertSource"></el-input>
							</el-col>
						</el-form-item>
						<div style="margin: 0 auto; text-align: left;">
							<el-button @click="unicodeToAscii()" type="primary">Unicode 转码 ASSCII</el-button>
							<el-button @click="asciiToUnicode()" type="success">ASSCII 转码 Unicode</el-button>
							<el-button @click="textReplacementAsciiToUnicode" type="warning">交换</el-button>
							<el-button @click="removeAsciiToUnicode" type="danger" plain>清空</el-button>
							<el-button  @click="copy(codesData.convertResult)" type="primary" plain>复制</el-button>
						</div>
						
						<el-row>
							<el-col :sm = "24">
								<el-form-item style="margin-top: 15px;">
									<el-input readonly  type="textarea" :rows="codesData.rows" resize='none'  v-model="codesData.convertResult"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-tab-pane>
					
					<!-- Unicode/中文编码转换 -->
					<el-tab-pane label="Unicode/中文编码转换" name="/chinese">
						<el-form-item label="">
							<el-col :sm = "24">
								<el-input  type="textarea" :rows="codesData.rows" resize='none'  v-model="codesData.chineseSource"></el-input>
							</el-col>
						</el-form-item>
						<div style="margin: 0 auto; text-align: left;">
							<el-button @click="unicodeTochinese()" type="success">Unicode 转码 中文</el-button>
							<el-button @click="chineseToUnicode()" type="primary">中文 转码 Unicode</el-button>
							<el-button @click="exchangeSourceResult()" type="warning">交换</el-button>
							<el-button @click="removeChineseToUnicode" type="danger" plain>清空</el-button>
							<el-button  @click="copy(codesData.chineseResult)" type="primary" plain>复制</el-button>
						</div>
						
						<el-row>
							<el-col :sm = "24">
								<el-form-item style="margin-top: 15px;">
									<el-input readonly  type="textarea" :rows="codesData.rows" resize='none'  v-model="codesData.chineseResult"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-tab-pane>
					
					<!-- utf8/中文编码转换 -->
					<el-tab-pane label="UTF8/中文编码转换" name="/utf8">
						<el-form-item label="">
							<el-col :sm = "24">
								<el-input  type="textarea" :rows="codesData.rows" resize='none'  v-model="codesData.utf8Source"></el-input>
							</el-col>
						</el-form-item>
						<div style="margin: 0 auto; text-align: left;">
							<el-button @click="utf8Convchinese()" type="success">UTF8 转码 中文</el-button>
							<el-button @click="chineseConvUtf8()" type="primary">中文 转码 UTF8</el-button>
							<el-button @click="exchangeUtf8SourceResult()" type="warning">交换</el-button>
							<el-button @click="removeUtfToChinese()" type="danger" plain>清空</el-button>
							<el-button  @click="copy(codesData.utf8Result)" type="primary" plain>复制</el-button>
						</div>
						
						<el-row>
							<el-col :sm = "24">
								<el-form-item style="margin-top: 15px;">
									<el-input readonly  type="textarea" :rows="codesData.rows" resize='none'  v-model="codesData.utf8Result"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-tab-pane>
					
					<!-- gbk/编码转换 -->
					<el-tab-pane label="GBK/UTF8编码转换" name="/gbk">
						<el-form-item label="">
							<el-col :sm = "24">
								<el-input  type="textarea" :rows="codesData.rows" resize='none'  v-model="codesData.gbkSource"></el-input>
							</el-col>
						</el-form-item>
						<div style="margin: 0 auto; text-align: left;">
							<el-button @click="gbkConvUtf8()" type="success">GBK 转码 UTF8</el-button>
							<el-button @click="utf8ConvGbk()" type="primary">UTF8 转码 GBK</el-button>
							<el-button @click="exchangeGBKSourceResult()" type="warning">交换</el-button>
							<el-button @click="removeGBKToChinese()" type="danger" plain>清空</el-button>
							<el-button  @click="copy(codesData.gbkResult)" type="primary" plain>复制</el-button>
						</div>
						
						<el-row>
							<el-col :sm = "24">
								<el-form-item style="margin-top: 15px;">
									<el-input readonly  type="textarea" :rows="codesData.rows" resize='none'  v-model="codesData.gbkResult"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-tab-pane>
				</el-tabs>
			</el-form>
		</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {unicodeToAscii as unitoAscii} from './codes'
import {asciiToUnicode as ascToUnicode} from './codes'
import {chineseToUnicode as chineseToUni} from './codes'
import {unicodeTochinese as uniTochinese} from './codes'
import {chineseToUtf8 as chineseToUtf} from './codes'
import {utf8ToChinese as utfToChinese} from './codes'
import {utf8ToGbk as utfToGbk} from './codes'
import {gbkToUtf8 as gbkToUtf} from './codes'

import {clipboard as copy} from '../../common/actionrecord'	
import { useRouter } from "vue-router";
    let codesData = ref({
		moduleid: 1030,
		tempCache:"",
		urlSource:"",
		urlResult:"",
        size: 10,
        rows: 10,
        text: "",
		urlSourceError:false,
		
		convertSource:"",
		convertResult:"",
		unicodeOrASSCII:"",
		tempAsciiToUnicodeCache:"",
    })
	
	//根据路由地址,定位tab页签
	const route = useRouter();
	codesData.value.activeName=route.currentRoute.value.path
	let handleClick = (tab, event) => {
		window.open(tab.props.name, '_parent')
		codesData.value.activeName=tab.props.name
		console.log(tab, event)
	}
	
	//url编码-编码方法
	let urlHandleEvent = (eventtype) => {
		var urlSource=codesData.value.urlSource
		if(urlSource.length>2083){
			codesData.value.urlSourceError = true;
			return;
		}
		if(eventtype==1){
			codesData.value.urlResult =encodeURI(urlSource);
		}
		if(eventtype==2){
			codesData.value.urlResult =decodeURI(urlSource);
		}
		moduleaction(codesData.value.moduleid,1);
	}

	//url编码-数据交换
	let textReplacement =() =>{
		codesData.value.tempCache=codesData.value.urlSource;
		codesData.value.urlSource=codesData.value.urlResult;
		codesData.value.urlResult=codesData.value.tempCache;
	}
	
	//url编码-清空
	let removeAsciiToUnicode = () => {
		codesData.value.convertSource="";
		codesData.value.convertResult="";
	}
	
	let unicodeToAscii= () =>{
		var content=codesData.value.convertSource;
		codesData.value.firstDraftUnicodeOrASSCIICache=content
		codesData.value.convertResult=unitoAscii(content)
		//模块事件 1.预览 2.使用
		moduleaction(codesData.value.moduleid,2);
	}
	//AsciiToUnicode
	let asciiToUnicode= () =>{
		var content=codesData.value.convertSource;
		codesData.value.convertResult=ascToUnicode(content)
		//模块事件 1.预览 2.使用
		moduleaction(codesData.value.moduleid,2);
	}
	
	//AsciiToUnicode-数据交换
	let textReplacementAsciiToUnicode =() =>{
		codesData.value.tempAsciiToUnicodeCache=codesData.value.convertSource;
		codesData.value.convertSource=codesData.value.convertResult;
		codesData.value.convertResult=codesData.value.tempAsciiToUnicodeCache;
	}
	
	let chineseToUnicode = () => {
		var content=codesData.value.chineseSource
		codesData.value.chineseResult=chineseToUni(content);
		moduleaction(codesData.value.moduleid,2);
	}
	
	let unicodeTochinese = () => {
		var content=codesData.value.chineseSource
		codesData.value.chineseResult=uniTochinese(content);
		moduleaction(codesData.value.moduleid,2);
	}
	
	let removeChineseToUnicode = () => {
		codesData.value.chineseSource=""
		codesData.value.chineseResult=""
	}
	
	let exchangeSourceResult = () => {
		codesData.value.chineseSourceTempCache=codesData.value.chineseSource
		codesData.value.chineseSource=codesData.value.chineseResult
		codesData.value.chineseResult=codesData.value.chineseSourceTempCache
	}
	
	let utf8Convchinese = () => {
		var content=codesData.value.utf8Source
		codesData.value.utf8Result=utfToChinese(content)
		moduleaction(codesData.value.moduleid,2)
	}
	
	let chineseConvUtf8 = () => {
		var content=codesData.value.utf8Source
		codesData.value.utf8Result=chineseToUtf(content)
		moduleaction(codesData.value.moduleid,2)
	}
	
	let exchangeUtf8SourceResult = () => {
		codesData.value.utf8SourceTempCache=codesData.value.utf8Source
		codesData.value.utf8Source=codesData.value.utf8Result
		codesData.value.utf8Result=codesData.value.utf8SourceTempCache
	}
	
	let removeUtfToChinese = () => {
		codesData.value.utf8Source=""
		codesData.value.utf8Result=""
	}
	
	let utf8ConvGbk = () => {
		var content=codesData.value.gbkSource
		codesData.value.gbkResult=utfToGbk(content);
		moduleaction(codesData.value.moduleid,2)
	}
	
	let gbkConvUtf8 = () => {
		var content=codesData.value.gbkSource
		codesData.value.gbkResult=gbkToUtf(content);
		moduleaction(codesData.value.moduleid,2)
	}
	
	let exchangeGBKSourceResult = () => {
		codesData.value.gbkSourceTempCache=codesData.value.gbkSource
		codesData.value.gbkSource=codesData.value.gbkResult
		codesData.value.gbkResult=codesData.value.gbkSourceTempCache
	}
	
	let removeGBKToChinese = () => {
		codesData.value.gbkSource=""
		codesData.value.gbkResult=""
	}
	
</script>

<style>
</style>