/**
 * 中文转Unicode方法
 */
export let chineseToUnicode = (str) => {
    var value = '';
    for (var i = 0; i < str.length; i++) {
        value += '\\u' + replenish_zero(parseInt(str.charCodeAt(i)).toString(16));
    }
    return value;
}

let  replenish_zero = (str) => {
    if (str != null && str != '' && str != 'undefined') {
        if (str.length == 2) {
            return '00' + str;
        }
    }
    return str;
}

/**
 * Unicode转中文方法
 */
export let unicodeTochinese = (str) =>{
    str = str.replace(/(\\u)(\w{1,4})/gi, function ($0) {
        return (String.fromCharCode(parseInt((escape($0).replace(/(%5Cu)(\w{1,4})/g, "$2")), 16)));
    });
    str = str.replace(/(&#x)(\w{1,4});/gi, function ($0) {
        return String.fromCharCode(parseInt(escape($0).replace(/(%26%23x)(\w{1,4})(%3B)/g, "$2"), 16));
    });
    str = str.replace(/(&#)(\d{1,6});/gi, function ($0) {
        return String.fromCharCode(parseInt(escape($0).replace(/(%26%23)(\d{1,6})(%3B)/g, "$2")));
    });
    return str;
}

/**
 * Unicode转Ascii方法
 */
export let unicodeToAscii= (str) =>{
	    var code = str.match(/&#(\d+);/g);
	    var result= '';
	    for (var i=0; i<code.length; i++){
			result += String.fromCharCode(code[i].replace(/[&#;]/g, ''));
		}
	    return result;
	}
	
/**
 * Ascii转Unicode方法
 */
export let asciiToUnicode= (str) =>{
	    var result = '';
	    for (var i=0; i<str.length; i++){
			result+='&#' + str.charCodeAt(i) + ';';
		}
		return result;
	}
	
export let chineseToUtf8 = (str)=>{

    return str.replace(/[^\u0000-\u00FF]/g, function ($0) { return escape($0).replace(/(%u)(\w { 4 })/gi, "&#x$2; ") });// eslint-disable-line	}

export let utf8ToChinese =(str)=>{

    return unescape(str.replace(/&#x/g, '%u').replace(/; /g, ''));
	}

export let utf8ToGbk =(str)=>{	
	return str.replace(/[^\u0000-\u00FF]/g,function($0){return escape($0).replace(/(%u)(\w{4})/gi,"&#x$2;")});// eslint-disable-line
	}
	
export let gbkToUtf8 =(str)=>{	
	return unescape(str.replace(/&#x/g,'%u').replace(/;/g,''));
	}